export default [
  {
    path: 'home',
  },
  {
    path: 'features',
  },
  {
    path: 'pricing',
  },
  {
    path: 'testimonials',
  },
];
