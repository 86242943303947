import React from "react"
import { Helmet } from "react-helmet"

export default function ExternalScripts({ gaMeasurementId, facebookPixelId, facebookChat }) {
  if (!!gaMeasurementId || !!facebookPixelId || !!facebookChat) {
    return (
      <>
        <Helmet>
          {
            !!gaMeasurementId && (
              <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`}></script>
            )}
          {
            !!gaMeasurementId && (
              <script>
                {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag("js", new Date());

                gtag("config", "${gaMeasurementId}");
              `}
              </script>
            )
          }
          {
            !!facebookPixelId && (
              <script>{`
              !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
              n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
              document,"script","https://connect.facebook.net/en_US/fbevents.js");
              fbq("init", "${facebookPixelId}"); // Insert your pixel ID here.
              fbq("track", "PageView");
            `}</script>
            )
          }
        </Helmet>
        {
          !!facebookChat && (
            <>
              <div id="fb-root"></div>
              <div id="fb-customer-chat" className="fb-customerchat" />
            </>
          )
        }
        {
          !!facebookChat && (
            <script dangerouslySetInnerHTML={{
              __html: `var chatbox = document.getElementById("fb-customer-chat");
            chatbox.setAttribute("page_id", "${facebookChat.pageId}");
            chatbox.setAttribute("attribution", "biz_inbox");`}} />
          )
        }
        {
          !!facebookChat && (
            <script id="fb-sc" dangerouslySetInnerHTML={{
              __html: `window.fbAsyncInit = function() {
                FB.init({
                  xfbml            : true,
                  version          : 'v14.0'
                });
              };
        
              (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));`}} />
          )
        }
      </>
    );
  }
  return null;
}
