/** @jsx jsx */
import { jsx, Box, Container, Flex, Button, Image, Select } from 'theme-ui';
import Sticky from 'react-stickynode';
import { useState } from 'react';
import { DrawerProvider } from 'contexts/drawer/drawer-provider';
import NavbarDrawer from './navbar-drawer';
import Logo from 'components/logo';
import { NavLink } from 'components/link';
import { useI18next } from 'gatsby-plugin-react-i18next';

import menuItems from './header.data';
import lock from 'assets/images/icons/lock.png';

export default function Header({ openModal }) {
  const { languages, language, changeLanguage, t } = useI18next("header");
  const [state, setState] = useState({
    isMobileMenu: false,
    isSticky: false,
  });
  const handleCloseMenu = () => {
    setState({
      ...state,
      isMobileMenu: false,
    });
  };
  const onSelectLanguage = (event) => {
    changeLanguage(event.currentTarget.value)
  }
  const position = language === "ar" ? "left" : "right";

  return (
    <DrawerProvider>
      <Box sx={styles.headerWrapper}>
        <Sticky enabled={true} top={0} activeClass="is-sticky" innerZ={100}>
          <Box
            as="header"
            variant="layout.header"
            className={state.isMobileMenu ? 'is-mobile-menu' : ''}
          >
            <Container>
              <Box sx={styles.headerInner}>
                <Logo sx={styles.logo} isSticky={state.isSticky} />
                <Flex
                  as="nav"
                  sx={styles.navbar}
                  className={state.isMobileMenu ? 'navbar active' : 'navbar'}
                >
                  <Box
                    as="ul"
                    sx={styles.navList}
                    className={state.isMobileMenu ? 'active' : ''}
                  >
                    {menuItems.map(({ path }, i) => (
                      <li key={`navitem-${path}`}>
                        <NavLink
                          path={path}
                          xs={styles.navitemMargin}
                          label={t(path)}
                          onClick={handleCloseMenu}
                        />
                      </li>
                    ))}
                    <li>
                      <Select
                        defaultValue={language}
                        sx={styles.selectContainer}
                        onChange={onSelectLanguage}
                      >
                        {
                          languages.map((lang) => (
                            <option key={lang} value={lang}>
                              {t(lang)}
                            </option>
                          ))
                        }
                      </Select>
                    </li>
                  </Box>
                </Flex>
                <Flex sx={styles.buttonGroup}>
                  {/* <a
                    href={`https://dashboard.zzenz.com/auth/signin`}
                    sx={{ ...styles.login, [position]: "79px" }}
                  >
                    <Image src={lock} alt="lock icon" height={16} width={14} />
                    {t("login")}
                  </a> */}
                  <Button
                    variant="text"
                    sx={styles.getStarted}
                    className={"action-button"}
                  >
                    <a
                      // href={`https://dashboard.zzenz.com/auth/signup`}
                      sx={styles.primaryLink}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      onClick={openModal}
                    >
                      {t("contact_us")}
                    </a>
                  </Button>
                </Flex>
                <NavbarDrawer />
              </Box>
            </Container>
          </Box>
        </Sticky>
      </Box>
    </DrawerProvider>
  );
}

const styles = {
  headerWrapper: {
    background: "rgba(255,255,255,.97)",
    header: {
      position: 'fixed',
      left: 0,
      right: 0,
      py: [4],
      transition: 'all 0.3s ease-in-out 0s',
      '&.is-mobile-menu': {
        backgroundColor: 'white',
      },
    },
    '.is-sticky': {
      header: {
        backgroundColor: 'white',
        py: ['13px'],
        boxShadow: '0 6px 13px rgba(38,78,118,0.1)',
        background: "rgba(255,255,255,.8)",
        backdropFilter: "saturate(50%) blur(8px)",
      },
      ".action-button": {
        backgroundColor: '#7536C3',
        a: {
          color: "#ffffff",
          fontWeight: 700
        },
        "&:hover": {
          backgroundColor: '#2B9EF8',
          "a": {
            color: "#ffffff",
          }
        }
      }
    },
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // position: ['relative', null, null, 'static'],
  },
  logo: {
    mr: [null],
  },
  navbar: {
    display: ['none', null, null, null, 'flex'],
    alignItems: 'center',
    flexGrow: 1,
    li: {
      display: 'flex',
      alignItems: 'center',
      a: {
        cursor: 'pointer',
        transition: 'all 0.3s ease-in-out 0s',
      },
    },
  },
  navList: {
    display: ['flex'],
    listStyle: 'none',
    flexGrow: 1,
    justifyContent: 'center',
    p: 0,
    '.nav-item': {
      cursor: 'pointer',
      fontWeight: 400,
      padding: 0,
      margin: [null, null, null, null, '0 10px'],
    },
    '.active': {
      color: '#7536C3',
    },
  },
  selectContainer: {
    paddingRight: "30px",
    border: "none"
  },
  getStarted: {
    backgroundColor: "rgba(117, 54, 195, 0.2)",
    color: '#7536C3',
    p: ['0 16px'],
    minHeight: 45,
    display: ['none', null, null, null, 'flex'],
    "&:hover": {
      backgroundColor: '#7536C3',
      "a": {
        color: "#ffffff",
      }
    }
  },
  primaryLink: {
    textDecoration: "none"
  },
  buttonGroup: {
    gap: 6
  },
  login: {
    display: "flex",
    gap: 2,
    backgroundColor: 'transparent',
    position: ['absolute', null, null, null, 'static'],
    color: 'text',
    fontSize: [2],
    fontWeight: 500,
    top: '50%',
    p: 0,
    transform: ['translateY(-50%)', null, null, null, 'none'],
    border: 0,
    fontFamily: 'body',
    display: 'flex',
    alignItems: 'center',
    outline: 0,
    textDecoration: "none",
    img: {
      maxWidth: 14,
    },
  },
  menuButton: {
    position: 'relative',
    right: '-6px',
    p: 0,
  },
  closeButton: {
    height: '32px',
    padding: '0',
    minHeight: 'auto',
    width: '32px',
    position: 'relative',
    right: '-10px',
    path: {
      stroke: 'text',
    },
  },
};
