/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import ExternalScripts from './external-scripts';
import { useI18next } from 'gatsby-plugin-react-i18next';

function SEO({ description, lang, meta, title, image = "" }) {
  const { language } = useI18next();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            google_analytics
            facebook_chat_page_id
          }
        }
      }
    `
  );
  const gaMeasurementId = site.siteMetadata.google_analytics;
  const facebookChat = !!site.siteMetadata.facebook_chat_page_id === false
    ? undefined
    : {
      pageId: site.siteMetadata.facebook_chat_page_id,
      isChat: true,
      version: 'v14.0'
    };
  const metaDescription = description || site.siteMetadata.description;
  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:image`,
      content: `${site.siteMetadata.siteUrl}${image}`,
    },
    {
      property: `og:url`,
      content: `https://www.zzenz.com`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:url`,
      content: `https://www.zzenz.com`,
    },
    {
      property: `twitter:image`,
      content: `${site.siteMetadata.siteUrl}${image}`,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta);

  const htmlAttributes = {
    lang,
    dir: language === "ar" ? "rtl" : "ltr"
  };
  const headLinks = [{
    rel: "canonical",
    href: `https://www.zzenz.com/`
  }];

  return (
    <>
      <Helmet
        htmlAttributes={htmlAttributes}
        title={title}
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        meta={metaTags}
        link={headLinks}
      />
      <ExternalScripts
        gaMeasurementId={gaMeasurementId}
        facebookChat={facebookChat}
      />
    </>
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

export default SEO;
