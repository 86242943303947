/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'components/link';
import LogoSvg from 'components/icons/logo';

export default function Logo({ isWhite, ...props }) {
  return (
    <Link path="/" sx={styles.logo} {...props}>
      <LogoSvg height={50} width={50} />
      <span className='logo__text'>Zzenz</span>
    </Link>
  );
}
const styles = {
  logo: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    textDecoration: "none",
    gap: ".35rem",
    svg: {
      height: 'auto',
      width: [50, null, '100%'],
    },
    span: {
      textTransform: "uppercase",
      fontWeight: "900",
      fontSize: "1.5rem",
      color: "transparent",
      background: "linear-gradient(51.64deg, #2B9EF8 -65.75%, #7536C3 98.66%)",
      "-moz-background-clip": "text",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
    }
  },
};
