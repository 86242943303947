/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Header from './header/header';
import Footer from './footer/footer';

export default function Layout({ openModal, children }) {
  const mainStyle = {
    variant: 'layout.main',
    overflow: 'hidden',
  };

  return (
    <React.Fragment>
      <Header openModal={openModal} />
      <main
        sx={mainStyle}
      >
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
}

